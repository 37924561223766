<template>
  <div class="home">
    <el-row :gutter="20">
      <el-col
        :span="12"
        :offset="6"
        :xs="{ span: 24, offset: 0 }"
      >
        <transition name="fade" mode="out-in">
          <question
            v-if="currentQuestion"
            :key="currentQuestion._id"
            :question="currentQuestion"
            :submitted="submitted"
            :display-mark="false"
            class="question"
          />
        </transition>

        <div class="actions" v-if="questions.length">

            <el-button
              icon="el-icon-check"
              @click="submitQuestion"
              type="primary"
              round
              v-if="!submitted"
            >
              Потврди
            </el-button>

            <el-button
              icon="el-icon-delete"
              @click="removeQuestion"
              type="danger"
              round
              v-if="submitted"
            >
              Одбаци питање
            </el-button>

            <el-button
              icon="el-icon-d-arrow-right"
              @click="goToNextQuestion"
              type="primary"
              round
              v-if="submitted"
            >
              Наредно питање
            </el-button>
        </div>

        <div v-else>
            <p>
              Успешно си прошао сет питања.
              Одбачена питања можеш вратити кликом на <b>Започни поново</b> дугме.
            </p>
            <el-button
              icon="el-icon-refresh-right"
              @click="resetRemovedQuestions"
              type="primary"
              round
            >
              Започни поново
            </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ElButton from 'element-ui/lib/button';
import MessageBox from 'element-ui/lib/message-box';

import Question from './../components/tests/Question';

export default {
  name: "SignsQuiz",
  components: {
    Question,
    ElButton
  },
  data() {
    return {
      currentQuestionIndex: 0,
      submitted: false
    }
  },
  computed: {
    ...mapGetters({
      questions: 'getSignsQuestions'
    }),
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    }
  },
  methods: {
    ...mapActions({
      getQuestions: 'getSignsQuestions',
      moveToTrashQuestion: 'removeSignsQuestion',
      resetRemovedQuestions: 'resetRemovedQuestions'
    }),
    scrollToTop() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0;
      });
    },
    submitQuestion() {
      this.submitted = true;
    },
    goToNextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
      } else {
        this.currentQuestionIndex = 0;
      }

      this.scrollToTop();
      this.submitted = false;
    },
    removeQuestion() {
      MessageBox.confirm(
        `Ово питање ће бити избачено из сета питања.
         Потврдом бићете пребачени на следеће питање. Да ли сте сигурни?
        `, 'Упозорење', {
          confirmButtonText: 'Потврди',
          cancelButtonText: 'Одустани',
          type: 'warning'
        }).then(() => {
          this.submitted = false;
          this.moveToTrashQuestion(this.currentQuestion);
          if (this.currentQuestionIndex > this.questions.length - 1) {
            this.currentQuestionIndex = 0;
          }
          this.scrollToTop();
        }).catch(() => {
          // not confirmed
        });
    }
  },
  mounted() {
    this.getQuestions();
  }
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100%;
  display: flex;
}

.el-row {
  min-height: 100%;
  display: flex;
  flex: 1;
}

.el-col {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.question {
  flex: 2;
}

.actions {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
    .el-button {
      margin-bottom: 5px;
      margin-left: 0;
      width: 100%;
    }
  }
</style>

<style lang="scss">
.el-message-box {
  @media screen and (max-width: 600px) {
    width: 95% !important;
  }
}
</style>